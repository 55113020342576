.promotion {
  font-family: $helvetica;

  &__header {
    position: relative;
    width: 100%;
    height: 60px;
    background-color: #fff;
    border-bottom: 1px solid #eae5e1;

    &-close-icon {
      position: absolute;
      top: 20px;
      left: 15px;
    }

    &-title {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 0 40px;
      color: #2f1f19;
      font-size: 16px;
      font-weight: 500;
      align-items: center;

      i {
        margin-top: -3px;
      }
    }
  }

  &__payment-method {
    padding: 20px 15px;
    background-color: #fff;

    &-note {
      color: #ff586d;
      text-align: center;
      font-size: 16px;
      font-weight: 500;

      a {
        color: #ff586d;
      }
    }
  }

  &__info {
    padding: 15px;
    background-color: #fff;
    color: #2f1f19;
    font-size: 14px;
    margin-bottom: 10px;

    &--payment {
      text-align: center;
      margin-bottom: 0;
      padding: 10px 0 0;
    }

    &--proof {
      padding: 15px 0;
    }

    &--payed {
      padding: 0;
      text-align: center;
      background-color: inherit;
      font-size: 15px;
    }

    &--bottom-border {
      border-bottom: 1px solid #eae5e1;
    }
  }

  &__terms {
    padding: 10px 15px 0;
    background-color: #fff;
    border-top: 1px solid #eae5e1;
    border-bottom: 1px solid #eae5e1;
    margin-bottom: 10px;

    &-title {
      display: block;
      color: #98918a;
      text-align: center;
      font-size: 12px;
      margin-bottom: 10px;
    }

    &-radio-btns {
      border-bottom: 1px solid #eae5e1;

      &:last-child {
        border-bottom: 0;
      }
    }

    &--radio-reverse {
      flex-direction: row-reverse;
      justify-content: flex-end;
      gap: 10px;
      padding: 17px 0;
    }

    &--no-bottom-space {
      margin-bottom: 0;
      border-bottom: 0;
    }
  }

  &__confirmation {
    text-align: center;
    color: #98918a;
    font-size: 12px;
    padding: 15px;

    a {
      color: #98918a;
      text-decoration: underline;
    }
  }

  &__more-ads {
    padding: 0 15px 20px;
    text-align: center;
    color: #98918a;
    font-size: 15px;

    a {
      color: #4264fd;
      text-decoration: none;
    }
  }

  &__proof {
    background-color: #fff;
    text-align: center;
    border-bottom: 1px solid #eae5e1;

    &-container {
      padding: 20px 0;
      margin: 0 30px;
      border-top: 1px solid #eae5e1;
    }

    &-title {
      color: #4264fd;
      font-size: 16px;
      font-weight: 500;
    }
  }
}
